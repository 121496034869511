
import {Component, Vue} from 'vue-property-decorator';
import AgencyForm from "@/components/agency/AgencyForm.vue";
import {ROUTES} from "@/data";
import {StoreAction, StoreMutation} from "@/types";
import {Action, Mutation} from "vuex-class";
import {isValidEmail, passwordStrength} from "@/helpers/commons";

@Component({
    name: "RegisterAgency",
    components: {AgencyForm}
})
export default class RegisterAgency extends Vue {
    @Action('actions/registerAgency') registerAgency!: StoreAction;

    @Mutation('auth/setToken') setToken!: StoreMutation;
    @Mutation('auth/setLoginVerified') setLoginVerified!: StoreMutation;

    errors:any = {};
    globalErrors:any = {};
    loading = false;
    isCompany = false;
    email = "";
    password = "";
    confirmPassword = "";
    name = "";
    city = "";
    firstname = "";
    lastname = "";
    phone = "";

    passwordStrength = passwordStrength;

    get loginRoute() {
        return {name: ROUTES.LOGIN};
    }

    get registerCompanyRoute() {
        return {name: ROUTES.REGISTER_COMPANY};
    }

    goToLogin() {
        this.$router.push({name: ROUTES.LOGIN});
    }

    validate() {
        if (!this.name) {
            this.errors.name = {id: "Validation.Any.Empty"};
        }
        if (!this.city) {
            this.errors.city = {id: "Validation.Any.Empty"};
        }
        if (!this.firstname) {
            this.errors.firstname = {id: "Validation.Any.Empty"};
        }
        if (!this.lastname) {
            this.errors.lastname = {id: "Validation.Any.Empty"};
        }
        // if (!this.phone) {
        //     this.errors.phone = {id: "Validation.Any.Empty"};
        // }
        // if (!isValidPhone(this.phone)) {
        //     this.errors.phone = {id: "Validation.Phone.Invalid"};
        // }
        if (!isValidEmail(this.email)) {
            this.errors.email = {id: "Validation.Email.Invalid"};
        }
        if (!this.email) {
            this.errors.email = {id: "Validation.Any.Empty"};
        }
        if (!this.password) {
            this.errors.password = {id: "Validation.Any.Empty"};
        }
        if (this.confirmPassword !== this.password) {
            this.errors.confirmPassword = {id: "Auth.NewPassword.NotMatch"};
        }
        if (!this.confirmPassword) {
            this.errors.confirmPassword = {id: "Validation.Any.Empty"};
        }

        return !Object.keys(this.errors).length;
    }

    async submit() {
        this.errors = {};
        this.globalErrors = [];

        this.loading = true;
        try {
            if (this.validate()) {
                // let action = this.registerAgency;
                //
                // if (this.isCompany) {
                //     action = this.registerCompany;
                // }

                // const result = await action({
                //     email: this.email,
                //     name: this.name,
                //     address: {city: this.city},
                //     lastname: this.lastname,
                //     firstname: this.firstname,
                //     phone: this.phone,
                //     password: this.password,
                // });
                // this.setToken(result.token);
                // this.setLoginVerified(true);
                // await this.$router.push({name: ROUTES.APP._ROOT});
            }
        } catch (e) {
            console.log(e);
            this.errors = e.errors;
            this.globalErrors = e._global;
        }
        this.loading = false;
    }

}
